/**
* Created by tudou on 2020/4/20 21:32.
*/
<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="info" v-if="paramsId">
        <!--        <span class="mr-20">所属门店：{{form.storeName}}</span>-->
        <!--        <span class="mr-20">创建人：{{form.createUserName}}</span>-->
        <span class="mr-20">创建时间：{{ form.createTime }}</span>
        <span class="mr-20">用户状态：{{ form.statusName }}</span>
        <span class="mr-20 text-bold">账号余额：{{ form.amount }} RM</span>
      </div>
      <div slot="content">
        <a-card title="会员信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-row>
              <a-col :span="14">
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="姓名" prop="realName">
                      <a-input
                        v-model="form.realName"
                        placeholder="请输入姓名"
                        ::maxLength="20"
                        :disabled="isAble"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="手机号" prop="mobile">
                      <a-input
                        v-model="form.mobile"
                        placeholder="请输入手机号"
                        :maxLength="11"
                        :disabled="!!paramsId"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                  <!--<a-col :span="2" style="line-height: 38px;" v-if="!!paramsId && !isAble">-->
                  <!--<a href="javascript:;" @click="handleChangePhone">更换手机号</a>-->
                  <!--</a-col>-->
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="会员卡号" prop="cardNo">
                      <a-input
                        v-model="form.memberCode"
                        placeholder="请输入会员卡号"
                        :maxLength="11"
                        :disabled="isAble"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="身份证号" prop="idCard">
                      <a-input
                        v-model="form.idCard"
                        placeholder="请输入身份证号"
                        :maxLength="18"
                        :disabled="isAble"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="邮箱" prop="idCard">
                      <a-input
                        v-model="form.email"
                        placeholder="请输入邮箱"
                        :maxLength="18"
                        :disabled="isAble"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="性别" prop="gender">
                      <a-radio-group v-model="form.sex" :disabled="isAble">
                        <a-radio :value="1">男</a-radio>
                        <a-radio :value="2">女</a-radio>
                      </a-radio-group>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="出生日期" prop="birthDate">
                      <a-date-picker
                        v-model="form.birthDate"
                        type="date"
                        :disabled="isAble"
                        placeholder="请选择出生日期"
                        style="width: 100%"
                      >
                      </a-date-picker>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="结束时间" prop="endTimeStr">
                      <a-date-picker
                        v-model="form.endTimeStr"
                        type="date"
                        format="YYYY-MM-DD 23:59:59"
                        :disabled="isAble"
                        placeholder="请选择结束日期"
                        style="width: 100%"
                      >
                      </a-date-picker>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12">
                    <a-form-model-item label="速度等级" prop="speedFlag">
                      <a-select v-model="form.speedFlag" :disabled="isDetail">
                        <a-select-option :value="1" >
                          低速
                        </a-select-option>
                        <a-select-option :value="2">
                          中速
                        </a-select-option>
                        <a-select-option :value="3">
                          高速
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="10">
                <a-tabs default-active-key="1" @change="callback">
                  <a-tab-pane key="1" tab="未使用" style="height:600px">
                    <div class="couponCard">
                      <template v-for="item in useCouponRecords">
                        <div
                          class="couponCardItem isChecked"
                          :class="{
                            'is-used': !!item.used,
                            'type-1': +item.types === 1,
                            'type-2': +item.types === 2,
                            'type-3': +item.types === 3,
                            'type-4': +item.types === 4,
                            'type-3': +item.types === 5,
                          }"
                        >
                          <div class="yuan"></div>
                          <div class="left">
                            <div class="l-inner">
                              <div class="l1">
                                ￥<span
                                  style="font-weight: bold; line-height: 1"
                                  >{{ item.cash }}</span
                                >
                              </div>
                              <div class="l2" style="margin-top: 8px">
                                {{
                                  item.conditioncash > 0
                                    ? "满 " + item.conditioncash + " RM可用"
                                    : "无金额限制"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="right">
                            <!-- <div class="name">{{ item.couponName }}</div> -->
                            <div style="display: flex; flex-direction: row">
                              <div class="ccc">
                                {{ dictCouponType(item.types) }}
                              </div>
                              <div class="Title_name">
                                {{ item.couponName }}
                              </div>
                            </div>
                            <div class="indate">{{ computedIndate(item) }}</div>
                            <div class="isShare">
                              {{
                                item.sharecnt > 1
                                  ? "可叠加(" + item.sharecnt + "次)"
                                  : "不可叠加"
                              }}
                            </div>
                          </div>
                          <div class="checked-ouline" v-if="!!item.checked">
                            <a-icon type="check-circle" />
                          </div>
                        </div>
                      </template>
                    </div>
                  </a-tab-pane>
                  <a-tab-pane key="2" tab="已使用" force-render>
                    <div class="couponCard">
                      <template v-for="item in usedCouponRecords">
                        <div
                          class="couponCardItem isChecked"
                          :class="{
                            'is-used': !!item.used,
                            'type-1': +item.types === 1,
                            'type-2': +item.types === 2,
                            'type-3': +item.types === 3,
                            'type-4': +item.types === 4,
                            'type-3': +item.types === 5,
                          }"
                        >
                          <div class="yuan"></div>
                          <div class="left">
                            <div class="l-inner">
                              <div class="l1">
                                ￥<span
                                  style="font-weight: bold; line-height: 1"
                                  >{{ item.cash }}</span
                                >
                              </div>
                              <div class="l2" style="margin-top: 8px">
                                {{
                                  item.conditioncash > 0
                                    ? "满 " + item.conditioncash + " RM可用"
                                    : "无金额限制"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="right">
                            <!-- <div class="name">{{ item.couponName }}</div> -->
                            <div style="display: flex; flex-direction: row">
                              <div class="ccc">
                                {{ dictCouponType(item.types) }}
                              </div>
                              <div class="Title_name">
                                {{ item.couponName }}
                              </div>
                            </div>
                            <div class="indate">{{ computedIndate(item) }}</div>
                            <div class="isShare">
                              {{
                                item.sharecnt > 1
                                  ? "可叠加(" + item.sharecnt + "次)"
                                  : "不可叠加"
                              }}
                            </div>
                          </div>
                          <div class="checked-ouline" v-if="!!item.checked">
                            <a-icon type="check-circle" />
                          </div>
                        </div>
                      </template>
                    </div>
                  </a-tab-pane>
                </a-tabs>
              </a-col>
            </a-row>

            <!--<a-row>-->
            <!--<a-col :span="10">-->
            <!--<a-form-model-item label="备注" prop="remark">-->
            <!--<a-input v-model="form.remark" type="textarea" autoSize placeholder="请输入备注" :maxLength="100" :disabled="isAble"></a-input>-->
            <!--</a-form-model-item>-->
            <!--</a-col>-->
            <!--</a-row>-->
          </a-form-model>
        </a-card>
      </div>
    </form-frame>

    <a-modal v-model="changePhone.visible" title="更换手机号" :footer="null">
      <div class="p-20">
        <a-steps v-model="changePhone.step" size="small" disabled>
          <a-step title="身份认证" disabled></a-step>
          <a-step title="设置操作" disabled></a-step>
          <a-step title="完成" disabled></a-step>
        </a-steps>
      </div>
      <div class="change-phone">
        <span class="text-bold" v-if="changePhone.step === 0"
          >当前手机号：15011557228</span
        >
        <span class="text-bold" v-if="changePhone.step === 1"
          >设置绑定手机</span
        >
        <div class="mt-20">
          <a-input-group size="large">
            <!--<a-row :gutter="16">-->
            <!--<a-col :span="24">-->
            <!--<a-input addon-before="+86" placeholder="请输入常用手机号"></a-input>-->
            <!--</a-col>-->
            <!--</a-row>-->
            <a-row :gutter="16">
              <a-col :span="24" v-if="changePhone.step === 1">
                <a-input
                  class="mb-20"
                  addon-before="+86"
                  placeholder="请输入常用手机号"
                ></a-input>
              </a-col>
              <a-col :span="12">
                <a-input
                  placeholder="请输入手机验证码"
                  extra="验证码已发送至您的手机，120秒内有效"
                ></a-input>
              </a-col>
              <a-col :span="12">
                <a-button
                  class="x-inner"
                  type="primary"
                  ghost
                  icon="message"
                  size="large"
                  v-show="!changePhone.sendCode"
                  @click="handleSendCode"
                  >发送验证码</a-button
                >
                <div
                  class="change-phone__countdown"
                  v-show="changePhone.sendCode"
                >
                  <span>
                    <a-statistic-countdown
                      :value="changePhone.deadline"
                      @finish="handleFinish"
                    >
                    </a-statistic-countdown>
                  </span>
                  <span class="change-phone__countdown--info"> 后重新获取</span>
                </div>
              </a-col>
            </a-row>
          </a-input-group>
        </div>
        <div class="mt-20">
          <a-button
            class="mt-20"
            size="large"
            block
            type="primary"
            @click="handleCheckCode"
            v-if="changePhone.step === 0"
            >提交认证</a-button
          >
          <a-button
            class="mt-20"
            size="large"
            block
            type="primary"
            @click="handleDoChangePhone"
            v-if="changePhone.step === 1"
            >提交</a-button
          >

          <p class="mt-10">
            收不到短信验证码？<br />
            请检查手机网络并且核实手机是否屏蔽系统短信，如均正常请重新获取或稍后再试。
          </p>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { memberAdd, memberInfo } from "@/api/member";
import FormFrame from "@/components/frame/FormFrame.vue";
import formMixin from "@/mixin/formMixin";
import { toMoment } from "@/libs/utils";
import moment from "moment";
import { getAllCouponsByMemberId } from "@/api/coupon";
export default {
  name: "MemberForm",
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  props: {},
  data() {
    return {
      form: {
        speedFlag: 1,
        realName: "",
        sex: 1,
        mobile: "",
        email:"",
        memberCode: "",
        birthDate: null,
        idCard: "",
        endTimeStr: null,
      },
      rules: {
        realName: [
          {
            required: true,
            message: "请输入姓名",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
          },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "请输入正确的手机号",
          //   trigger: "blur",
          // },
        ],
        memberCode: [
          // {
          //   required: true,
          //   message: '请输入会员卡号'
          // }
        ],
      },
      changePhone: {
        visible: false,
        step: 0,
        deadline: null,
        sendCode: false,
      },
      usedCouponRecords: [],
      useCouponRecords: [],
      currentCouponRecordsBack: [],
      memberId: "",
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    },
    isAble() {
      return !!this.paramsId && this.$route.name === "memberInfo";
    },
  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.memberId = this.paramsId;
      this.getData(this.paramsId);
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    callback(key) {
      console.log(key);
    },
    // 会员信息
    getData(memberId) {
      var that = this;
      memberInfo(memberId).then((data) => {
        if (!data) {
          this.$message.error("请勿非法操作!");
          this.handleBack();
          return;
        }
        data.birthDate = toMoment(data.birthDate);
        if (data.endTime) {
          data.endTimeStr = moment(data.endTime).format("YYYY-MM-DD 23:59:59");
        }
        this.form = data;
        that.getMemebrCouponListRequest();
      });
    },
    // 保存
    handleSave() {
      const params = { ...this.form };
      if (params.endTimeStr != null) {
        params.endTimeStr = moment(params.endTimeStr).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      if (params.birthDate != null) {
        params.birthDate = moment(params.birthDate).format("YYYY-MM-DD");
      }
      console.log(params);
      memberAdd(params).then(() => {
        this.$message.success("操作成功！");
        this.handleBack();
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error("请按要求填写");
          return false;
        }
      });
    },

    // 更换手机号
    handleChangePhone() {
      this.changePhone.visible = true;
    },
    handleSendCode() {
      // 调用发送验证码的接口
      this.$message.info(" 验证码已发送，请向客户询问验证码");
      this.changePhone.deadline = Date.now() + 1000 * 10; // 60 * 2;
      this.changePhone.sendCode = true;
    },
    handleCheckCode() {
      this.changePhone.step = 1;
    },
    handleFinish() {
      this.changePhone.sendCode = false;
    },
    handleDoChangePhone() {
      this.$message.success("更改成功！");
      this.changePhone.visible = false;
    },
    filterMemberCoupon() {
      if (this.currentCouponRecordsBack.length == 0) return;
      //
      for (let i = 0; i < this.currentCouponRecordsBack.length; i++) {
        const item = this.currentCouponRecordsBack[i];
        const datestr = moment(new Date()).format("YYYY-MM-DD");
        // 过期的不能用
        // if (item.endTime < datestr) {
        //   this.usedCouponRecords.push(item);
        //   continue;
        // }
        if (item.used===1)
          this.usedCouponRecords.push(item);
        else
          this.useCouponRecords.push(item);
      }
    },
    // 获取会员下的优惠券
    getMemebrCouponListRequest() {
      const that = this;
      const _param = {
        memberid: this.memberId,
      };
      getAllCouponsByMemberId(_param).then((res) => {
        that.currentCouponRecordsBack = res;
        that.filterMemberCoupon();
      });
    },
    computedIndate(item) {
      const temp = `${item.startTime}至${item.endTime}`;
      return temp;
    },
    dictCouponType(type) {
      const typeOption = [
        { label: "体验券", value: 1 },
        { label: "优惠券", value: 2 },
        { label: "转赠券", value: 3 },
        { label: "礼包", value: 4 },
        { label: "充值券", value: 5 },
      ];
      return typeOption.filter((item) => {
        return item.value === +type;
      })[0].label;
    },
  },
};
</script>

<style lang="less" scoped>
// 优惠券样式开始

.yuan {
  position: absolute;
  top: 48px;
  left: -8px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: #fff;
}
// .Coupons{
// 	font-size: 13px; height:22px; line-height:20px; margin-right: 10px; display: inline-block;  padding: 0 6px; background-color: #ffa52f; border-radius: 100px; color: #fff;
// }
.Title_name {
  width: 130px;
  margin-top: 1.5%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 350;
  color: #333;
  line-height: 1;
}
// 优惠券样式结束

.change-phone {
  margin-bottom: 20px;
  padding: 0 50px;
}
.change-phone__countdown {
  display: flex;
}
.change-phone__countdown--info {
  padding: 0 5px;
  line-height: 36px;
}

.choose {
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: row;
}
.ccc {
  color: #fff;
  background-color: #409dff;
  font-size: 13px;
  height: 22px;
  line-height: 20px;
  margin-right: 10px;
  display: inline-block;
  padding: 0 6px;
  border-radius: 100px;
}
.couponCard {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 500px;
  box-sizing: border-box;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.couponCardItem {
  flex-shrink: 0;
  min-width: 300px;
  max-width: 320px;
  margin-right: 20px;
  height: 110px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 20px;
  .bbb {
    background: red;
  }
  .left {
    border-radius: 7px 0 0 7px;
    width: 100px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .l-inner {
      text-align: center;
      .l1 {
        span {
          font-size: 39px;
        }
      }
    }
  }
  .right {
    padding: 10px;
    position: relative;
    flex: 1;
    .name {
      font-size: 16px;
    }
    .indate {
      color: #adadad;
      font-size: 13px;
      margin-top: 6px;
    }
    .isShare {
      position: absolute;
      line-height: 35px;
      bottom: 0;
    }
  }
  .float-text {
    position: absolute;
    right: -6px;
    top: -8px;
  }
  .checked-ouline {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 34px;
    color: #ae6fda;
  }
}
.type-1 {
  border: 2px solid #ae6fda;
  .left {
    background: #ae6fda;
  }
  .bbb {
    background: #ae6fda;
  }
  .ccc {
    background: #ae6fda;
  }
  .checked-ouline {
    color: #ae6fda;
  }
}
.type-2 {
  border: 2px solid #ff6633;
  .left {
    background: #ff6633;
  }
  .bbb {
    background: #ff6633;
  }
  .ccc {
    background: #ff6633;
  }
  .checked-ouline {
    color: #ff6633;
  }
}
.type-3 {
  border: 2px solid #24c5c0;
  .left {
    background: #24c5c0;
  }
  .bbb {
    background: #24c5c0;
  }
  .ccc {
    background: #24c5c0;
  }
  .checked-ouline {
    color: #24c5c0;
  }
}
.type-4 {
  border: 2px solid #409dff;
  .left {
    background: #409dff;
  }
  .bbb {
    background: #409dff;
  }
  .checked-ouline {
    color: #409dff;
  }
}
.is-used {
  background: #cfcfcf;
  border: 2px solid #cfcfcf;
  .left {
    background: #959595;
  }
  .bbb {
    background: #959595;
  }
}
</style>
